






































































































































































































import { Validator } from '@/constant/Mixins';
import Component from 'vue-class-component';
import { Mixins, Watch } from 'vue-property-decorator';
import filters from '@/constant/Filters';
import { Action, State } from 'vuex-class';
import { FormCompareMotorModel } from '@/store/product/motor/types';
import { ServerResponse } from '@/services/response.types';
import { ProductAdditionalInterface, ProductAdditionalModel, ProductDataModel, ProductFeatureModel, ProductModel, ProductSummaryModel } from '@/store/product/types';
import { INSURANCES, PRODUCT_INSTALLMENT_MONTH_DIFF } from '@/constant/Enums';
import { CompareContentModel } from '@/store/product/mobil/types';

interface TableItem {
  id: string;
  name: string;
  provider_name: string;
  total: number;
  annual_price: string;
  sum_insured: string;
  type_insurance: string;
  rating: string;
  workshop: {
    local: number;
    others: number;
  };
  installment: { duration: number; unit: 'months'; nominal: number; }[];
  additional_fee: { label: string; nominal: number | string | ProductAdditionalInterface[]; additional?: any[]; }[];
  features: {
    medical_expanses_limit: boolean;
    personal_accident_limit: boolean;
    constructive_total_lost: boolean;
  };
  brand: string;
  quotation_id: string;
  compare: string;
  _showDetails: boolean;
}

@Component({
  name: 'listMotor',
  components: {
    ProductDetail: () => import('@/components/ProductDetail.vue'),
    FormMotor: () => import('@/components/formCompare/compareMotorcylce.vue'),
    callMe: () => import('@/components/FormCallMe.vue'),
  },
  filters,
})
export default class listMotor extends Mixins(Validator) {
  @Action('CompareAction', { namespace: 'product/motor' }) CompareAction!: (params?: any) => Promise<ServerResponse>;
  @Action('GetSummary', { namespace: 'product/motor' }) GetSummary!: (quotation_id: string, params?: any) => Promise<ServerResponse>;
  @Action('CompareProductAction', { namespace: 'product/motor' }) CompareProductAction!: (quotation_ids: FormData) => Promise<ServerResponse>;

  @State('onLoad', { namespace: 'product/motor' }) onLoad!: boolean;
  @State('data', { namespace: 'product/motor' }) data!: ProductDataModel;
  @State('form', { namespace: 'product/motor' }) form!: FormCompareMotorModel;
  @State('summary', { namespace: 'product/motor' }) summary!: ProductSummaryModel;
  @State('listCompare', { namespace: 'product/motor' }) listCompare!: CompareContentModel[];

  @Watch('OnComparing') OnComparingChange(value: boolean) {
    if (value) document.body.classList.add('overflow-hidden');
    else document.body.classList.remove('overflow-hidden');
  }

  public compareLimit = 3;
  public comparisons: TableItem[] = [];
  public selected: TableItem = null;

  public get fieldsCompare(): any[] {
    let fields = [
      {
        class: 'side-header compare-product-item',
        key: 'label',
        stickyColumn: false,
      },
    ];
    this.comparisons.forEach((item, i) =>
      fields.push({
        class: 'compare-product-item',
        key: `produk_${i + 1}`,
        stickyColumn: false,
      })
    );
    return fields;
  }

  public get comparisonsAside(): { label: string; product1?: TableItem; product2?: TableItem; product3?: TableItem; }[] {
    let rows = [
      { label: 'Label', key_ref: ['brand&provider_name'] },
      { label: 'Produk', key_ref: ['name'] },
      { label: 'Harga Motor', key_ref: ['sum_insured'] },
      { label: 'Harga Premi', key_ref: ['annual_price'] },
      // { label: 'Rating', key_ref: ['rating'] },
      { label: 'Tipe Asuransi', key_ref: ['coverage'] },
      { label: 'Medical Expenses Limit', key_ref: ['features', 'medical_expanses_limit'] },
      { label: 'Personal Accident Limit', key_ref: ['features', 'personal_accident_limit'] },
      { label: 'Constructive Total Loss', key_ref: ['features', 'constructive_total_lost'] },
      { label: 'Fitur', key_ref: ['feature'] },
      { label: 'Perlindungan Tambahan', key_ref: ['additional'] },
      // { label: 'Action', key_ref: [] },
    ].map((cols: any) => {
      this.comparisons.forEach((item, i) => (cols[`product_${i + 1}`] = { ...item, ...this.listCompare?.filter((v, i) => Number(v.product.product_id) === Number(item.id))[0]?.product || {} }));
      return cols;
    });

    return rows;
  }

  public showDetail = false;
  public showCallMe = false;
  public OnComparing = false;

  public selectedOrder = 0;

  public SortByOptions = [
    {
      text: 'The Lowest',
      value: 'lowest',
    },
    {
      text: 'The Highest',
      value: 'highest',
    },
    {
      text: 'Company A-Z',
      value: 'a-z',
    },
    {
      text: 'Company Z-A',
      value: 'z-a',
    },
  ];

  public fields = [
    {
      class: 'col-3',
      key: 'annual_price',
      label: 'Annual Price',
      sortable: false,
      _showDetails: true,
    },
    {
      class: 'col-3',
      key: 'installment',
      label: 'Installment',
    },
    {
      class: 'col-3',
      key: 'additional_fee',
      label: 'Additional Fee',
      sortable: false,
    },
    {
      class: 'col-3',
      key: 'features',
      label: 'Insurance Features and Extra',
      sortable: false,
      headspan: [
        {
          label: 'Medical Expenses Limit',
        },
        {
          label: 'Personal Accident Limit',
        },
        {
          label: 'Constructive Total Loss',
        },
      ],
    },
  ];

  public get items(): TableItem[] {
    let type_insurance = INSURANCES.filter((item) => item.value === this.form.coverage)[0].text;
    return this.data.listdata.map((item) => {
      let admninistration = Number(item.admin_fee) + Number(item.handling_fee);
      let additionalFee = Number(item.additional_premi) > 0 ? item.additional_premi : 0;
      let totalPremium = Number(item.discount_premium) + admninistration + additionalFee;

      return {
        id: item.product_id,
        name: item.product_name,
        provider_name: item.provider_name,
        total: (item.workshop.local ?? 0) + (item.workshop.others ?? 0),
        annual_price: totalPremium as any,
        workshop: item.workshop,
        sum_insured: item.sum_insured,
        type_insurance: 'Motor',
        coverage: INSURANCES.filter((v) => v.value === Number(item.coverage_type))[0].text || "Not Defined!",
        rating: item.rate_basic,
        installment: PRODUCT_INSTALLMENT_MONTH_DIFF.map((month) => {
          return {
            duration: month,
            unit: 'months',
            nominal: Number(totalPremium) / month,
          };
        }),
        additional_fee: [
          {
            label: 'Admninistration',
            nominal: admninistration,
          },
          {
            label: 'Additional',
            nominal: additionalFee,
            additional: item.additional instanceof Array ? item.additional : [],
          },
        ],
        features: {
          medical_expanses_limit: item.col1 === '1',
          personal_accident_limit: item.col2 === '1',
          constructive_total_lost: item.col3 === '1',
        },
        brand: item.logo,
        quotation_id: item.quotation_id,
        compare: item.compare,
        _showDetails: true,
      };
    });
  }

  public formCallMe = {
    name: '',
    email: '',
    phone: '',
  };

  public created(): void { }

  public mounted(): void {
    window.onresize = () => {
      if (this.comparisons.length > 0) this.OnCollapseShow(`compare-product-img`);
      if (this.OnComparing) this.OnCollapseShow(`comparer-product-img`);
    };
  }

  /**
   * OnClickCompare
  */
  public OnClickCompare() {
    this.OnComparing = true;
    let form = new FormData;
    this.comparisons.forEach((v, i) => form.append(`param${i + 1}`, v.quotation_id));
    this.CompareProductAction(form).then((res) => {
      this.OnComparing = true;
      // this.comparisonsAside = this.comparisonsAside.map((i)=>{...i, })
    }).finally(() => this.OnComparing = true);
  }

  /**
   * OnAddCompare
   */
  public OnAddCompare(item: TableItem) {
    this.comparisons.push(item);
  }

  /**
   * OnDeleteCompare
   */
  public OnDeleteCompare(item: TableItem) {
    this.comparisons = this.comparisons.filter((v) => v.id !== item.id);
    if (this.comparisons.length === 0) this.OnComparing = false;
  }

  /**
   * GetDetailSummary
   */
  public GetDetailSummary(item: ProductModel) {
    this.GetSummary(item.quotation_id).then((res) => {
      if (res.data) this.showDetail = true;
    });
  }

  /**
     * GetRefferenceCompateAttribute
    */
  public GetRefferenceCompateAttribute(item: { label: string; key_ref: any[]; product_1?: TableItem; product_2?: TableItem; product_3?: TableItem; }, product_ref: string) {
    let value: any = item[product_ref];
    item.key_ref.forEach((key) => {
      if (key instanceof Array) value = value[key[0]];
      else if (typeof key === 'string') {
        if (key.indexOf('&') >= 0) {
          value = '';
          key.split('&').forEach((k, i) => {
            let refVal: string = item[product_ref];
            refVal = refVal[k] || '';

            let isHTML = refVal.indexOf('http') >= 0;
            if (isHTML) value += `<img ref="product-logo" src="${refVal}" class="img-thumbnail d-block mx-auto" style="height: 100px;" />`;
          });
        } else value = value[key];
      }
    });

    if (typeof value === 'boolean') return value ? `<i class="fa fa-check h4 text-success" aria-hidden="true"></i>` : `<i class="fa fa-times h4 text-danger" aria-hidden="true"></i>`;
    else if (Number(value) >= 1000) return this.formatCurrency(value);
    else if (value instanceof Array) return "<ul>" + value.map((item: any) => `<li>${item.detail_ind ?? item.additional_category_name_ind}</li>`).join("") + "</ul>";
    else return item.key_ref.length > 0 ? value : null;
  }

  /**
   * onClickCallMe
   */
  public onClickCallMe(item: TableItem) {
    this.selected = item;
    this.showCallMe = !this.showCallMe;
  }

  /**
   * OnCollapseShow
   */
  public OnCollapseShow(target: string) {
    let imgs = this.$refs[target] as HTMLImageElement[];
    if (imgs) {
      let height = imgs[imgs.length - 1].clientHeight;
      imgs.forEach((img: HTMLImageElement) => {
        img.style.height = `${height}px`;
      });
    }
  }
}
